<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Facturas Inválidas</h1>
		</div>
		<br />

		<DataGrid :config="dataGridConfig" :data="facturas" :select="seleccionar" @actions="dataGridActions" @ready="buscar_botones">
			<div class="col-md-2">
				<button class="btn warning-btn" @click="cargar_facturas">Recargar</button>
			</div>
			<div class="col-md-2">
				<button class="btn secondary-btn" @click="revisar_factura">Revisar factura</button>
			</div>
		</DataGrid>

		<Modal v-if="factura" :options="{width: '80vw',close:true}" @close="cancelar_revisar_factura" class="bpb-modal">
			<h2 class="title">Factura</h2>
			<div class="body">
				<div class="row form-group">
					<label for="proveedor" class="col-form-label col-sm-2">Proveedor</label>
					<div class="col-sm-4">
						<input v-model="factura.proveedor_nombre" type="text" name="proveedor" id="proveedor" class="form-control" readonly>
					</div>
					<label for="uuid" class="col-form-label col-sm-2">UUID</label>
					<div class="col-sm-4">
						<input v-model="factura.uuid" type="text" name="uuid" id="uuid" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="rfc_emisor" class="col-form-label col-sm-2">RFC Emisor</label>
					<div class="col-sm-4">
						<input v-model="factura.rfc_emisor" type="text" name="rfc_emisor" id="rfc_emisor" class="form-control" readonly>
					</div>
					<label for="rfc_receptor" class="col-form-label col-sm-2">RFC Receptor</label>
					<div class="col-sm-4">
						<input v-model="factura.rfc_receptor" type="text" name="rfc_receptor" id="rfc_receptor" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="total" class="col-form-label col-sm-2">Total</label>
					<div class="col-sm-4">
						<input v-model="total_procesada" type="text" name="total" id="total" class="form-control" readonly>
					</div>
					<label for="subtotal" class="col-form-label col-sm-2">Subtotal</label>
					<div class="col-sm-4">
						<input v-model="subtotal_procesada" type="text" name="subtotal" id="subtotal" class="form-control" readonly>
					</div>					
				</div>
				<div class="row form-group">
					<label for="serie" class="col-form-label col-sm-2">Serie</label>
					<div class="col-sm-4">
						<input v-model="factura.serie" type="text" name="serie" id="serie" class="form-control" readonly>
					</div>
					<label for="folio" class="col-form-label col-sm-2">Folio</label>
					<div class="col-sm-4">
						<input v-model="factura.folio" type="text" name="folio" id="folio" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="serie" class="col-form-label col-sm-2">Fecha emisión</label>
					<div class="col-sm-4">
						<input v-model="factura.fecha_emision" type="text" name="serie" id="serie" class="form-control" readonly>
					</div>
					<label for="folio" class="col-form-label col-sm-2">Fecha enviado</label>
					<div class="col-sm-4">
						<input v-model="factura.created_at" type="text" name="folio" id="folio" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="serie" class="col-form-label col-sm-2">SAT Código estatus</label>
					<div class="col-sm-4">
						<input v-model="factura.sat_codigo_estatus" type="text" name="serie" id="serie" class="form-control" readonly>
					</div>
					<label for="folio" class="col-form-label col-sm-2">SAT Estado</label>
					<div class="col-sm-4">
						<input v-model="factura.sat_estado" type="text" name="folio" id="folio" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="serie" class="col-form-label col-sm-2">Otras razones</label>
					<div class="col-sm-10">
						<input v-model="factura.otras_razones" type="text" name="serie" id="serie" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-2">Conceptos</label>
					<div class="col-sm-10">
						<table style="width: 100%">
							<thead>
								<th>ClaveProdServ</th>
								<th>ClaveUnidad</th>
								<th>Descripción</th>
								<th>Cantidad</th>
								<th>Valor unitario</th>
								<th>Importe</th>
							</thead>
							<tbody>
								<tr v-for="concepto in factura.conceptos">
									<td>{{ concepto.clave_prod_serv }}</td>
									<td>{{ concepto.clave_unidad }}</td>
									<td>{{ concepto.descripcion }}</td>
									<td>{{ concepto.cantidad }}</td>
									<td>${{ parseFloat(concepto.valor_unitario).toFixed(2) }}</td>
									<td>${{ parseFloat(concepto.importe).toFixed(2) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if ="factura.estatus == 1 || factura.estatus == 3" class="row form-group">
					<label for="observacion" class="col-form-label col-sm-2">Agregar observaciones</label>
					<div class="col-sm-8">
						<input v-model="observacion" type="text" name="observacion" id="observacion" class="form-control">
					</div>
					<div class="col-sm-2 text-center">
						<button class="btn secondary-btn" @click="agregar_observacion" :disabled="factura.estatus == 0">Agregar</button>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'
	import {VMoney} from 'v-money'

	import account from '@/api/account'
	import api from '@/apps/tesoreria/api/tesoreria'

	export default {
		components: {
			DataGrid, Modal, VMoney
		}
		,data: () => ({
			dataGridConfig: {
				name: 'facturas'
				,cols: {
					proveedor_nombre: 'Proveedor'
					,uuid: 'UUID'
					,total: 'Total'
					,subtotal: 'Subtotal'
					,error: 'Error'
					,created_at: 'Fecha subido'
					,id: {
						text: 'Documentos'
						,ordeable: false
					}
				}
				,filters: {
					cols: {
						id: 'ID'
						,proveedor_nombre: 'Proveedor'
						,uuid: 'UUID'
						,total: 'Total'
						,subtotal: 'Subtotal'
						,sat_codigo_estatus: 'SAT Código estatus'
						,sat_estatus: 'SAT Estatus'
						,created_at: 'Fecha de creación'
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					total: function(val,row,vue) {
						return '$'+vue.$helper.moneyFormat(val);
					}
					,subtotal: function(val,row,vue) {
						return '$'+vue.$helper.moneyFormat(val);
					}
					,proveedor: function(val) {
						return val.nombre;
					}
					,error: function(val,row,vue) {
						let error = '<ul>';

						if (row.sat_codigo_estatus)
							error += '<li>'+row.sat_codigo_estatus+'</li>';

						if (row.sat_estatus)
							error += '<li>'+row.sat_estatus+'</li>';

						if (row.otras_razones)
							error += '<li>'+row.otras_razones+'</li>';

						error += '</ul>';

						return error;
					}
					,estatus: function(val) {
						let mssg = '';
						switch(val) {
							case 0:
								mssg = '<span style="color:#FA3030;font-weight:bold">Rechazada</span>';
								break;
							case 1:
								mssg = '<span style="color:#4545FF;font-weight:bold">Sin revisar</span>';
								break;
							case 2:
								mssg = '<span style="color:#FF9E44;font-weight:bold">Aceptada sin revisión</span>';
								break;
							case 3:
								mssg = '<span style="color:#4AD627;font-weight:bold">En revisión por director de Finanzas</span>';
								break;
							case 4:
								mssg = '<span style="color:#5A96E2;font-weight:bold">En revisión por director de Finanzas y General</span>';
								break;
							case 5:
								mssg = '<span style="color:#5A96E2;font-weight:bold">En proceso de pago</span>';
								break;
							case 6:
								mssg = '<span style="color:#5A96E2;font-weight:bold">Pagada</span>';
								break;
						}

						return mssg
					}
					,id: function(val, row, vue) {
						return '<button class="btn principal-btn" name="download_files" value="'+val+'" data-json="'+encodeURI((JSON.stringify(row)))+'">Descargar</button>';
					}
				}
			}
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,facturas: []
			,factura: null
			,seleccionadas: []
			,seleccionar: false
			,observacion: null
			,rechazar: false
			,pagar: false
			,pago: {
				banco: null
				,cuenta: null
				,monto: null
			}
		})
		,created: function() {
			this.cargar_facturas();
		}
		,methods: {
			cargar_facturas() {
				api.obtener_facturas_invalidas(this.options)
				.then(res => {
					this.$log.info('res', res);
					this.facturas = res.data.data;

					this.dataGridConfig.paginator.pagina_actual = res.data.current_page;
					this.dataGridConfig.paginator.total_registros = res.data.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.data.per_page);
				})
				.catch(err => {
					this.$log.info('err', err);

					this.$helper.showAxiosError(err, 'Error al obtener facturas');
				})
			}
			,dataGridActions: function(tipo, data) {
				this.$log.info('tipo', tipo);
				if (tipo == 'options') {
					this.options = data;
					this.cargar_facturas();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,buscar_botones: function() {
				this.$log.info('buscando botones');
				document.querySelectorAll('button[name=download_files]').forEach(btn => {
					// this.$log.info('btn', btn);
					btn.removeEventListener('click',this.descargar_archivos);
					btn.addEventListener('click', this.descargar_archivos);
				});
			}
			,descargar_archivos: async function(e) {
				let factura = JSON.parse(decodeURI(e.target.getAttribute('data-json')));

				await api.descargar_documentos_invalidos(factura.id, factura.uuid);
			}
			,revisar_factura: function() {
				if (this.seleccionadas.length > 0) {
					this.factura = this.seleccionadas[0];
					this.$log.info('factura', this.factura);
				}else {
					this.$helper.showAxiosError('Tienes que seleccionar una factura', 'Error');
				}
			}
			,cancelar_revisar_factura: function() {
				this.factura = null;
				this.seleccionar = false;
			}
			,actualizar_factura: function(estatus) {
				if (estatus == 2) {
					this.factura.estatus = estatus;

					api.actualizar_factura(this.factura.id, this.factura)
					.then(res => {
						this.$log.info('res', res);
						this.factura = null;
						this.seleccionar = false;
						this.cargar_facturas();
					})
					.catch(err => {
						this.$log.error('err', err);

						this.$helper.showAxiosError(err, 'Error al actualizar factura');
					})
				}else if (estatus == 6) {
					this.pagar = true;
				}else {
					this.rechazar = true;
				}
			}
			,agregar_observacion: function() {
				if (this.observacion && this.observacion.length > 5) {
					api.enviar_observaciones_factura(this.factura.id, {observacion: this.observacion})
					.then(res => {
						this.$log.info('res', res);
						this.factura.observaciones = res.data;
						this.observacion = null;
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
				}else {
					this.$helper.showAxiosError('Tienes que enviar una observación mas descriptiva (mínimo 5 caracteres)', 'Error');
				}
			}
			,rechazar_factura: async function() {
				if (this.observacion && this.observacion.length > 5) {
					await this.agregar_observacion();

					this.factura.estatus = 0;
					await api.actualizar_factura(this.factura.id, this.factura)
					.then(res => {
						this.$log.info('res', res);

						this.rechazar = false;
						this.observacion = null;
						this.cancelar_revisar_factura();
						this.cargar_facturas();
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err, 'Error al rechazar factura');
					})
				}else {
					this.$helper.showAxiosError('Tienes que enviar una observación mas descriptiva (mínimo 5 caracteres)', 'Error');
				}
			}
			,pagar_factura: function() {
				let formData = new FormData();
				formData.append('banco',this.pago.banco);
				formData.append('monto',this.pago.monto);
				formData.append('cuenta',this.pago.cuenta);

				this.$log.info('doc', document.getElementById('pago_comprobante').files[0]);
				formData.append('documento',document.getElementById('pago_comprobante').files[0]);

				api.pagar_factura(this.factura.id, formData)
				.then(res => {
					this.$log.info('res', res);
					this.cancelar_pago();
					this.seleccionar = false;
					this.cargar_facturas();
				})
				.catch(err => {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err,'Error');
				})
			}
			,cancelar_pago: function() {
				this.pagar = false;
				this.factura = null;
				this.pago = {
					banco: null
					,monto: null
					,cuenta: null
				}

				document.getElementById('pago_comprobante').value = null;
			}
		}
		,computed: {
			total_procesada: function() {
				return '$'+this.factura.total
			}
			,subtotal_procesada: function() {
				return '$'+this.factura.subtotal
			}
		}
	}
</script>

<style lang="scss" scope>
	.observaciones {
		max-height: 200px;
		overflow: auto;
		
		.row {
			margin-right: 3px;
			padding: 3px;

			span.nombre {
				font-weight: bold;
			}

			span.fecha {
				font-size: 0.8em;
			}
		}

		.row:nth-child(2n+0) {
			background-color: #E6E6E6;
		}
	}

	.razon_rechazo {
		width: 100%;
		height: 170px !important;
		resize: none;
	}
</style>